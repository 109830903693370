@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  text-align: center;
  background: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-image: url("./assets/images/body-bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel-item-margin {
  margin: 1rem;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 7px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
